<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 class="tit_section">품목 등록</h2>
      </div>
      <div class="body_section">
        <ItemInfo
          v-if="!model.isModifyMode || !_.isEmpty(model.objRaw)"
          :model.sync="model"
          :itemStatusList="itemStatusList"
        />
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickCancel">취소</button>
      </div>
      <div class="area_right">
        <button
          v-if="model.isModifyMode"
          type="submit"
          class="btn_primary btn_large"
          @click="onClickEditComfirm"
        >
          수정
        </button>
        <button v-else type="submit" class="btn_primary btn_large" @click="onClickAlertComfirm">
          등록요청
        </button>
      </div>
    </Sticky>
    <template v-slot:popup>
      <AlertPopup
        v-if="isAlertCancel"
        :alertText="
          isModifyMode ? '품목 수정을 취소하시겠습니까?' : '품목 등록을 취소하시겠습니까?'
        "
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickAlertCancel"
        @onClickPrimary="onClickAlertOk"
      />
      <AlertPopup
        v-if="isAlertComfirm"
        alertText="품목을 등록하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertComfirm"
        @onClickPrimary="onClickConfirm"
      />
      <AlertPopup
        v-if="isEditComfirm"
        alertText="품목을 수정하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertComfirm"
        @onClickPrimary="onClickEdit"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableHead from "@/components/shared/TableHead";
import Sticky from "@/components/layout/content/Sticky";
import AlertPopup from "@/components/layout/popup/AlertPopup";
import ItemInfo from "@/components/admin/item/write/ItemInfo";

import PageMixin from "@/mixins/PageMixin";

import ApiService from "@/services/ApiService";
import ItemWriteModel from "./ItemWriteModel";
import LocalStorageManager from "@/LocalStorageManager";
import { makePathWithQuery } from "@/utils/urlUtils";

export default {
  name: "ItemWrite",
  components: {
    PageWithLayout,
    TableHead,
    Sticky,
    AlertPopup,
    ItemInfo,
  },
  mixins: [PageMixin],
  data() {
    return {
      model: new ItemWriteModel(),
      isModifyMode: false,
      isAlertCancel: false,
      isAlertComfirm: false,
      isEditComfirm: false,
    };
  },
  computed: {
    itemStatusList() {
      const arr = [
        { id: "R", code: "R", desc: "대기", isDisabled: this.model.status !== "R" ? true : false },
        { id: "C", code: "C", desc: "사용중", isDisabled: false },
        { id: "S", code: "S", desc: "사용중지", isDisabled: false },
        { id: "J", code: "J", desc: "사용불가", isDisabled: false },
      ];
      return arr;
    },
  },
  created() {
    this.isModifyMode = this.$route.meta.isModifyMode;
    const { id } = this.$route.params;
    this.model.id = id;
    this.model.isModifyMode = this.isModifyMode;
    if (this.isModifyMode) {
      this.getData();
    }
  },
  methods: {
    async getData() {
      const path = `${this.$apiPath.ITEM}/${this.model.id}`;
      const result = await ApiService.shared.getData(path);

      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }

      const { empNo } = LocalStorageManager.shared.getUserData();
      if ((!this.$isAdmin && data.status !== "R") || (!this.$isAdmin && data.regId !== empNo)) {
        this.alert("잘못된접근입니다.", () => {
          this.$router.push({ path: this.$routerPath.ITEM_LIST });
        });
        return;
      }

      this.model.setData(data);
    },

    onClickEdit() {
      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }
      this.submit();
    },
    onClickConfirm() {
      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }
      this.submit();
    },
    async submit() {
      const obj = this.model.getData();
      const result = await ApiService.shared.post(this.$apiPath.ITEM, obj);

      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      this.isAlertComfirm = false;
      if (this.isModifyMode) {
        this.$router.push(makePathWithQuery(this.$routerPath.ITEM_LIST, "", this.$route.query));
      } else {
        this.$router.push({ path: this.$routerPath.ITEM_LIST });
      }
    },
    onClickCancel() {
      this.isAlertCancel = true;
    },
    onClickAlertOk() {
      if (this.isModifyMode) {
        this.$router.push({ path: `${this.$routerPath.ITEM_VIEW}/${this.model.id}` });
      } else {
        this.$router.push({ path: this.$routerPath.ITEM_LIST });
      }
    },
    onClickAlertCancel() {
      this.isAlertCancel = false;
    },
    onClickAlertComfirm() {
      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }
      this.isAlertComfirm = true;
    },
    onClickCloseAlertComfirm() {
      this.isAlertComfirm = false;
      this.isEditComfirm = false;
    },
    onClickEditComfirm() {
      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }
      this.isEditComfirm = true;
    },
  },
};
</script>
